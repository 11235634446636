import { useCallback, useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import Cancel from "../assets/images/cancel.png";
import ProductImagePlaceholder from "../assets/images/uploadImg.svg";
import { useEffect } from "react";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import Select from "react-select";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon2.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import FieldValidationError from "../components/error-messages/field-validation-error";
import getCroppedImgr, { dataURLtoFile } from "../utils/dataURLtoFile";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Auth from "../libs/auth";
import { PuffLoader } from "react-spinners";
import { customStyles } from "../utils/react-select-input-style";

function PostSocialModal(props) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  const [users, setUsers] = useState([]);
  const user = Auth.getCurrentUser();
  const [Find, setFind] = useState(false);
  const [editPost, seteditPost] = useState(false);
  const [PostArray, setProductArray] = useState([]);
  const [PostSubCategoryArray, setProductSubCategoryArray] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [PostData, setPostData] = useState({
    social_post_type_id: "",
    social_sub_post_type_id: "",
    icon: "",
    social_post_type_value: "",
    social_sub_post_type_value: "",
    description: "",
    tagged: [],
  });
  const [PostDataErr, setPostDataErr] = useState({
    social_post_type_id: false,
    social_sub_post_type_id: false,
    descrition: "",
    image: false,
  });
  const [ImageIndex, setImageIndex] = useState();
  const [show, setShow] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [categoryPage, setcategoryPage] = useState(0);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const cat = useRef(null);
  const subCat = useRef(null);

  const CloseRef = useRef();
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageArray[ImageIndex],
        croppedAreaPixels,
        rotation
      );
      imageArray[ImageIndex] = croppedImage;
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, imageArray]);
  function intialCat() {
    post("/general/category_listing", { type: "social_post_type", page: 1 })
      .then((response) => {
        setProductArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  }
  useEffect(() => {
    if (categoryPage === 0) {
    } else {
      intialCat();
    }
  }, [categoryPage]);
  function SubCat() {
    post("/general/category_listing", {
      type: "social_sub_post_type",
      page: 1,
      parent_id: PostData?.social_post_type_id,
    })
      .then((response) => {
        setProductSubCategoryArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the errror", e);
      });
  }
  async function SubmitPost(e) {
    e.preventDefault();
    const formdata = new FormData();

    if (props?.socialData?.id != undefined) {
      if (PostData?.social_post_type_id != "") {
        if (PostData?.social_sub_post_type_id === "") {
          setPostDataErr((p) => ({ ...p, social_sub_post_type_id: true }));
          return false;
        }
      }

      if (PostData?.social_sub_post_type_value === "") {
        formdata.append(
          "social_sub_post_type_id",
          props?.socialData?.category?.id
        );
      } else {
        formdata.append(
          "social_sub_post_type_id",
          PostData?.social_sub_post_type_id
        );
      }
      if (
        PostData?.description != undefined &&
        PostData?.description.trim() === ""
      ) {
        setPostDataErr((p) => ({ ...p, descrition: true }));
        return false;
      }
      if (imageArray.length === 0) {
        // setPostDataErr((p) => ({ ...p, image: true }));
        // return false;
      }

      formdata.append("description", PostData?.description);

      imageArray.map(async (item, index) => {
        if (item?.substring(0, 4) == "blob") {
          let d = ImageGeqtting(item);
          d.then((res) => {
            formdata.append(`media[${index}]`, dataURLtoFile(res, "left.png"));
          });
        } else {
          formdata.append(`media[${index}]`, dataURLtoFile(item, "left.jpeg"));
        }
      });
      setLoading(true);
      const url = `/social_post/edit/${props?.socialData?.id}`;
      setTimeout(async () => {
        const response = await postwithOu(url, getAuthConfig(), formdata)
          .then((res) => {
            if (res.status === 200) {
              swal(
                "Success!",
                "Post for social is Updated Successfully!",
                "success"
              );
              CloseRef.current.click();
              props.UpdateTrue();
              setLoading(false);

              navigate("/my-post", { state: "scrollToDiv" });
            }
          })
          .catch((err) => {
            alert(err.response.message);
          });
      }, 2000);
    } else {
      if (PostData?.social_post_type_value.trim() === "") {
        setPostDataErr((p) => ({ ...p, social_post_type_id: true }));
        return false;
      }
      if (PostData?.social_sub_post_type_value.trim() === "") {
        setPostDataErr((p) => ({ ...p, social_sub_post_type_id: true }));
        return false;
      }
      if (
        PostData?.description != undefined &&
        PostData?.description.trim() === ""
      ) {
        setPostDataErr((p) => ({ ...p, descrition: true }));
        return false;
      }
      if (imageArray.length === 0) {
        // setPostDataErr((p) => ({ ...p, image: true }));
        // return false;
      }

      formdata.append(
        "social_sub_post_type_id",
        PostData?.social_sub_post_type_id
      );
      formdata.append("description", PostData?.description);

      if (PostData?.tagged.length > 0) {
        PostData?.tagged.map((item, index) => {
          if (PostData?.description.includes(item?.name)) {
            formdata.append(`tagged_users_id[${index}]`, item?.id);
          }
        });
      }

      imageArray.map(async (item, index) => {
        if (item?.substring(0, 4) == "blob") {
          let d = ImageGeqtting(item);
          d.then((res) => {
            formdata.append(`media[${index}]`, dataURLtoFile(res, "left.png"));
          });
        } else {
          formdata.append(`media[${index}]`, dataURLtoFile(item, "left.jpeg"));
        }
      });
      setLoading(true);

      const url = "/social_post/store";
      setTimeout(async () => {
        const response = await postwithOu(url, getAuthConfig(), formdata)
          .then((res) => {
            if (res.status === 200) {
              swal(
                "Success!",
                "Post for social is created SuccessFully!",
                "success"
              );
              setPostData((p) => ({ ...p, description: "" }));
              setPostData((p) => ({ ...p, icon: "" }));
              setPostData((p) => ({ ...p, social_post_type_id: "" }));
              setPostData((p) => ({ ...p, social_post_type_value: "" }));
              setPostData((p) => ({ ...p, social_sub_post_type_id: "" }));
              setPostData((p) => ({ ...p, social_sub_post_type_value: "" }));
              setPostData((p) => ({ ...p, tagged: [] }));
              setUsers([]);
              setImageArray([]);
              CloseRef.current.click();
              props.UpdateTrue();
              setLoading(false);

              cat.current.commonProps.setValue("");
              subCat.current.commonProps.setValue("");
              navigate("/home", { state: "scrollToDiv" });
            }
          })
          .catch((err) => {
            alert(err.response.message);
          });
      }, 2000);
    }
  }

  async function ImageGeqtting(item) {
    const ddata = await getCroppedImgr(item);

    return ddata;
  }

  useEffect(() => {
    if (props?.socialData) {
      seteditPost(true);
      setPostData((p) => ({
        ...p,
        description: props?.socialData?.description,
      }));
      setPostData((p) => ({ ...p, icon: props?.socialData?.category?.icon }));
      setPostData((p) => ({
        ...p,
        social_sub_post_type_id: props?.socialData?.category?.id,
      }));

      if (props?.socialData?.media != undefined) {
        props?.socialData?.media.map((item, index) => {
          setImageArray((current) => [...current, item?.file]);
        });
      }

      // setImageArray([props?.socialData?.media)
    }
  }, [props?.socialData]);

  const filterdata = async (Name) => {
    let url;
    if (Name != undefined) {
      url = `/connection/listing?type=friends&search=${Name}`;
    } else {
      url = `/connection/listing?type=friends`;
    }

    await get(url, getAuthConfig())
      .then((res) => {
        setUsers(res.data.data.connections);
      })
      .catch((err) => {});
  };

  const ref = useRef(null);
  return (
    <>
      <div
        className="modal fade post-social-modal"
        id="PostSocialModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="PostSocialModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Post To Social
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="post-social-head row">
                  <div className="user-profile col-sm">
                    <div className="user-profile-image">
                      <img
                        alt=""
                        src={user?.profile_image}
                        className="profile-pic"
                      />
                    </div>
                    <div className="user-profile-content">
                      <div className="user-profile-name">
                        <h6>
                          {user?.name}
                          <span>(Profile ID: {user?.id})</span>
                        </h6>
                        <span className="post-tag">
                          {user?.is_buyer === 1 ? "Buyer" : "Seller"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      padding: "20px 40px",
                      width: "100%",
                    }}
                  >
                    <div className="select-wrap">
                      <div className="row">
                        <div className="col-sm">
                          <Select
                            ref={cat}
                            placeholder={
                              props?.socialData?.category?.parent?.name
                                ? props?.socialData?.category?.parent?.name
                                : "Select Post Type"
                            }
                            id="category"
                            onFocus={(e) => {
                              e.preventDefault();
                              intialCat();
                            }}
                            options={PostArray.map(function (productArray) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })}
                            onKeyDown={(e) => {}}
                            onChange={(e) => {
                              setProductSubCategoryArray([]);

                              setPostData((p) => ({
                                ...p,
                                social_post_type_id: e.value,
                              }));
                              setPostData((p) => ({
                                ...p,
                                social_post_type_value: e.label,
                              }));
                              setPostData((p) => ({
                                ...p,
                                social_sub_post_type_id: "",
                              }));
                              setPostDataErr((p) => ({
                                ...p,
                                social_post_type_id: false,
                              }));
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                              setcategoryPage(0);
                            }}
                            onMenuScrollToBottom={(e) => {
                              if (PostArray?.has_more === true) {
                                let p = categoryPage + 1;
                                setcategoryPage(p);
                              }
                            }}
                            styles={{
                              ...customStyles(darkMode),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: "8px", // Rounded corners for the dropdown
                                marginTop: "0px", // No margin between the input and the dropdown
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for the dropdown
                                maxHeight: "200px", // Fixed height for the dropdown
                                overflowY: "auto",
                                background: darkMode ? "#1C2226" : "#fff", // Background color for dropdown in dark mode
                                color: darkMode ? "#fff" : "#000",
                              }),
                            }}
                            menuPlacement="auto"
                            menuPosition="absolute"
                          ></Select>

                          {PostDataErr?.social_post_type_id === true ? (
                            <FieldValidationError message="Please select post type" />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm">
                          <Select
                            ref={subCat}
                            placeholder={
                              props?.socialData?.category?.name &&
                              PostData?.social_post_type_id === ""
                                ? props?.socialData?.category?.name
                                : "Post Sub Type"
                            }
                            id="category"
                            onFocus={(e) => {
                              e.preventDefault();
                              if (PostData?.social_post_type_id != "") {
                                SubCat();
                              }
                            }}
                            options={PostSubCategoryArray.map(function (
                              productArray
                            ) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                                icon: productArray.icon,
                              };
                            })}
                            onKeyDown={(e) => {}}
                            onChange={(e) => {
                              setPostData((p) => ({
                                ...p,
                                social_sub_post_type_id: e.value,
                              }));
                              setPostData((p) => ({
                                ...p,
                                icon: e.icon,
                              }));

                              setPostData((p) => ({
                                ...p,
                                social_sub_post_type_value: e.label,
                              }));
                              setPostDataErr((p) => ({
                                ...p,
                                social_sub_post_type_id: false,
                              }));
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                            styles={{
                              ...customStyles(darkMode),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: "8px", // Rounded corners for the dropdown
                                marginTop: "0px", // No margin between the input and the dropdown
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for the dropdown
                                maxHeight: "200px", // Fixed height for the dropdown
                                overflowY: "auto",
                                background: darkMode ? "#1C2226" : "#fff", // Background color for dropdown in dark mode
                                color: darkMode ? "#fff" : "#000",
                              }),
                            }}
                          ></Select>
                          {PostDataErr?.social_sub_post_type_id === true ? (
                            <FieldValidationError message="Please select sub post type" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="post-social-description">
                      <div className="sticky-top text-center">
                        {users.length > 0 &&
                          users?.map((item, indes) => {
                            return (
                              <>
                                <label
                                  className="Hover_user w-100"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    function replaceChar(
                                      origString,
                                      replaceChar,
                                      index
                                    ) {
                                      let firstPart = origString.substr(
                                        0,
                                        index
                                      );

                                      // let lastPart = origString.substr(index + 1);

                                      let newString = firstPart + replaceChar;

                                      return newString;
                                    }
                                    let description = PostData?.description;
                                    let lastIndexOf =
                                      description?.lastIndexOf("@") + 1;

                                    setPostData((p) => ({
                                      ...p,
                                      description: replaceChar(
                                        description,
                                        item?.name,
                                        lastIndexOf
                                      ),
                                    }));
                                    setPostData((p) => ({
                                      ...p,
                                      tagged: PostData?.tagged.concat(item),
                                    }));
                                    setFind(false);
                                    setUsers([]);
                                  }}
                                >
                                  {item?.name}
                                </label>
                              </>
                            );
                          })}
                      </div>
                      <textarea
                        ref={ref}
                        className="form-input"
                        placeholder="Write something..."
                        value={PostData?.description}
                        onChange={(e) => {
                          if (e.target.value.endsWith("@")) {
                            setFind(true);
                          }
                          if (e.target.value.includes("@") && Find === true) {
                            let arr = e.target.value.indexOf("@");
                            if (
                              e.target.value.lastIndexOf("@") + 1 !=
                              e.target.value.length
                            ) {
                              let Name = e.target.value.slice(
                                e.target.value.lastIndexOf("@") + 1,
                                e.target.value.length
                              );
                              filterdata(Name);
                            } else {
                              filterdata();
                            }
                          }
                          // if(e.target.value.endsWith("@")){
                          //   // filterdata();
                          // }
                          setPostData((p) => ({
                            ...p,
                            description: e.target.value,
                          }));
                          setPostDataErr((p) => ({ ...p, descrition: false }));
                        }}
                        style={{
                          padding: "20px",
                          borderRadius: "10px",
                        }}
                      ></textarea>

                      {PostDataErr?.descrition === true ? (
                        <FieldValidationError message="Please enter some description" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="upload-social-images field-set">
                      <div className="field-set-label">
                        <span style={{ color: "red" }}>
                          (Max 3 images can be uploaded)*
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <div
                            style={{ width: "120px" }}
                            className="form-field"
                          >
                            <div className="file-upload upload-button">
                              <input
                                type="file"
                                id="upload_prod_img1"
                                accept="image/*"
                                onChange={(e) => {
                                  if (
                                    !(
                                      e.target.files[0].type === "image/png" ||
                                      e.target.files[0].type === "image/jpg" ||
                                      e.target.files[0].type === "image/jpeg" ||
                                      e.target.files[0].type === "image/gif" ||
                                      e.target.files[0].type === "image/svg"
                                    )
                                  ) {
                                    alert("Only images allowed");
                                    return false;
                                  } else if (e.target.files[0].size > 2097152) {
                                    alert("Not More than 2 MB is allowded");
                                    return false;
                                  } else {
                                    if (imageArray?.length < 3) {
                                      setImageArray((current) => [
                                        ...current,
                                        URL.createObjectURL(e.target.files[0]),
                                      ]);
                                      setPostDataErr((p) => ({
                                        ...p,
                                        image: false,
                                      }));
                                    } else {
                                      setPostDataErr((p) => ({
                                        ...p,
                                        image: true,
                                      }));
                                    }
                                  }
                                }}
                              />
                              <label
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                                htmlFor="upload_prod_img1"
                              >
                                <img
                                  style={{ padding: "5px" }}
                                  src={ProductImagePlaceholder}
                                />
                                <span>Upload Images</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {PostDataErr?.image === true ? (
                          imageArray?.length < 0 ? (
                            <FieldValidationError message="Please add Atleast one image" />
                          ) : imageArray?.length > 3 ? (
                            <FieldValidationError message="Max 3 images can be uploaded" />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {imageArray.length > 0 &&
                          imageArray.map((item, index) => {
                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                  }}
                                  class="col-sm-3"
                                >
                                  <div className="form-field">
                                    <div className="file-upload upload-prod-image">
                                      <input
                                        type="file"
                                        id="upload_social_img1"
                                      />
                                      <label htmlFor="upload_social_img1">
                                        <img src={item} alt="" />
                                        <button className="cancel-button">
                                          <img
                                            alt=""
                                            src={Cancel}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              const filtered =
                                                imageArray.filter((obj) => {
                                                  return obj === item;
                                                });

                                              if (filtered.length >= 1) {
                                                setImageArray((current) =>
                                                  current.filter(
                                                    (fruit) => fruit !== item
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        </button>
                                      </label>
                                    </div>
                                  </div>

                                  <span
                                    onClick={(e) => {
                                      setImageIndex(index);
                                      setShow(true);
                                    }}
                                    style={{ marginRight: "12px" }}
                                  >
                                    <EditIcon />
                                  </span>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "18px",
                      width: "50%",
                    }}
                    className="social-post-type field-set"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "20px",
                      }}
                      className="field-set-label"
                    >
                      {/* <label>Post Type</label> */}
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li
                            style={{ fontSize: "14px" }}
                            className="breadcrumb-item"
                          >
                            {PostData?.social_post_type_value}
                          </li>
                          <li
                            style={{ fontSize: "14px" }}
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {PostData?.social_sub_post_type_value}
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="social-post-image">
                      {PostData?.icon && (
                        <img
                          style={{
                            border: "1px solid #bdbdbd",
                            padding: "10px",
                            height: "200px",
                            width: "200px",
                            borderRadius: "10px",
                          }}
                          alt=""
                          src={PostData?.icon}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ marginLeft: "25px", marginBottom: "20px" }}
              className="card-footer"
            >
              <button
                disabled={
                  PostData?.social_sub_post_type_id != "" &&
                  PostData?.social_post_type_id != "" &&
                  PostData?.description != undefined
                    ? false
                    : true
                }
                className="button button-primary"
                onClick={(e) => {
                  SubmitPost(e);
                }}
                style={{ borderRadius: "5px" }}
              >
                {loading === true ? <PuffLoader /> : " Submit and Post"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title>
            <h3>Crop Your Image</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div
              className="col-lg-12 col-md-12 col-12"
              style={{ height: "60vh" }}
            >
              <Cropper
                image={imageArray[ImageIndex]}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={3}
                zoomWithScroll={true}
                showGrid={true}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              showCroppedImage();
              handleClose();
            }}
          >
            Save
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PostSocialModal;
