import React, { useEffect, useState } from "react";
import "../common/scss/pages/settings.scss";
import "../common/scss/pages/support.scss";
import Header from "../common/header";
import { ReactComponent as ArrowDownIcon } from "../assets/images/arrow-down.svg";
import SupportForm from "../components/Support/SupportForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getAuthConfig, post } from "../libs/http-hydrate";
function Support() {
  const location = useLocation();
  const [supportDetails, setSupportDetails] = useState({
    subject: "",
    title: "",
    description: "",
    short_order_id: "",
  });
  const [attachment, setAttachment] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setSupportDetails((p) => ({ ...p, short_order_id: location?.state?.id }));
  }, [location?.state?.id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupportDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAttachment = (e) => {
    const newAttachments = [...attachment, ...e.target.files];
    setAttachment(newAttachments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("subject", supportDetails.subject);
    formData.append("title", supportDetails.title);
    formData.append("description", supportDetails.description);
    if (location?.state?.id) {
      formData.append("short_order_id", supportDetails.short_order_id);
    }
    if (attachment.length > 0) {
      attachment.forEach((attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }

    setLoading(true);
    try {
      const response = await post(
        "/support-ticket/create",
        formData,
        getAuthConfig()
      );

      if (response) {
        toast.success("Your issue is raised successfully");
        setLoading(false);
        navigate(`/support-chat`, {
          state: {
            id: response?.data?.data?.id,
            supportDetails: response?.data?.data,
            attachment: attachment?.length > 0 ? attachment : "",
          },
        });

        setSupportDetails({
          subject: "",
          title: "",
          description: "",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid support-page">
          <div className="page-title">
            <h6>BusiMeet Help Center | 24x7 Customer Care Support</h6>
          </div>
          <div className="layout-grid-box-column2 ">
            <div className="layout-grid layout-grid--left">
              <div className="card sidebar-nav support-sidebar">
                <div className="sidebar-title">
                  <h6>TYPE OF ISSUE</h6>
                  <a
                    className="collapse-button mobile-view"
                    data-toggle="collapse"
                    href="#collapseSidebar"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseSearch"
                  >
                    <ArrowDownIcon />
                  </a>
                </div>
                <ul className="collapse desktop-view" id="collapseSidebar">
                  <li
                    className={`sidebar-nav--item ${
                      location?.state?.order ? "" : "active"
                    }`}
                  >
                    <Link to={"/support"}>Help with your issues</Link>
                  </li>
                  <li
                    className={`sidebar-nav--item ${
                      location?.state?.order ? "active" : ""
                    }`}
                  >
                    <Link to={"/support-other"}>Help with your order</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="layout-grid layout-grid--main">
              <SupportForm
                type={location?.state?.order ? "order" : "issue"}
                loading={loading}
                handleAttachment={handleAttachment}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                supportDetails={supportDetails}
                attachment={attachment}
                order={location?.state?.order}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
